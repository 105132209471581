import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { NODE_NAME, SENTRY_DSN } from "../constants";
import packageJson from "../../package.json";

export const isSentryEnabled = SENTRY_DSN !== undefined;

export const initErrorReportingIfEnabled = () => {
  if (!isSentryEnabled) return;

  Sentry.init({ dsn: SENTRY_DSN, environment: NODE_NAME, release: packageJson.version });
};

export const useWatchCurrentUserForErrorReporting = () => {
  const { currentUser } = useSelector(state => state.auth);
  useEffect(() => {
    Sentry.setUser(currentUser);
  }, [currentUser]);
};

export const captureException = (error, tags = {}) => {
  if (!isSentryEnabled) return;
  let tagsWithNodeName = { ...tags, nodeName: NODE_NAME };
  Sentry.captureException(error, { tags: tagsWithNodeName });
};
