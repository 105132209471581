import React from "react";

const Button = ({
  children,
  onClick,
  type = "button",
  variant = "primary",
  bold = false,
  className = ""
}) => {
  const baseClasses =
    "inline-flex justify-center px-4 py-2 border text-sm leading-5 rounded-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out";

  const variantClasses = {
    primary: "text-white bg-blue-500 hover:bg-blue-600 border-transparent",
    secondary: "text-slate-700 bg-white hover:text-slate-500 border-gray-300",
    danger: "text-white bg-red-500 hover:bg-red-600 border-transparent",
    success: "text-white bg-green-500 hover:bg-green-600 border-transparent"
  };

  const boldClasses = bold ? "font-bold" : "font-medium";

  return (
    <button
      type={type}
      onClick={onClick}
      className={`${baseClasses} ${variantClasses[variant]} ${boldClasses} ${className}`}
    >
      {children}
    </button>
  );
};

export default Button;
