import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { CheckIcon } from "@heroicons/react/outline";
import { getUserContext } from "../../../features/auth/authSlice";

const ContentComplete = ({ content, platformData, iconOnly, readOnly, buttonClass }) => {
  const dispatch = useDispatch();
  const { completedContents } = useSelector(state => state.auth);
  const [completed, setCompleted] = useState(completedContents.includes(content.id));
  const [completedBtnLoading, setCompletedBtnLoading] = useState(false);

  const buttonStyle = { backgroundColor: platformData.primaryColor };
  if (!completed) {
    buttonStyle.backgroundColor = platformData.lightColor;
    buttonStyle.color = platformData.primaryColor;
    buttonStyle.borderColor = platformData.primaryColor;
  }

  useEffect(() => {
    setCompleted(completedContents.includes(content.id));
  }, [content, completedContents]);

  const handleCompletedClick = async () => {
    if (readOnly) return;

    setCompletedBtnLoading(true);
    try {
      await axios.post(`/api/content/${content.id}/complete`);
      dispatch(getUserContext());
    } catch (e) {
      console.error("Error marking content as completed", e);
    } finally {
      setCompletedBtnLoading(false);
    }
  };

  if (readOnly && !completed) return null;

  return (
    <button
      className={`${
        completed ? "text-white  bg-green-600" : " text-gray-900  bg-white  border-gray-800 "
      } inline-flex items-center border ${buttonClass} border-transparent shadow-sm  font-medium rounded-full text-white  focus:outline-none  focus:shadow-lg  ${
        readOnly ? "px-1 leading-normal" : " text-center  "
      } disabled:bg-gray-200 rounded-full focus:outline-none`}
      style={buttonStyle}
      disabled={completedBtnLoading}
      onClick={handleCompletedClick}
    >
      {completed && <CheckIcon className="h-4 p-0 w-4 m-auto" />}
      {!iconOnly && completed && <p className="mx-1">Completed!</p>}
      {!iconOnly && !completed && "Mark completed"}
    </button>
  );
};

export default ContentComplete;
