import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  composeQueryParams,
  initialDashboardPageMetadata,
  mergeDshbPageMetadataObjects,
  paramsSerializer
} from "../../utils";

export function selectUserProgress(id) {
  return state => state.content.userProgress.find(progress => progress.ContentId === id);
}

export function selectProgressLoaded(state) {
  return state.content.progressLoaded;
}

export const readAllContentsForDashboardAsync = createAsyncThunk(
  "content/readAllForDashboard",
  async (payload = {}, { getState }) => {
    try {
      const { metadata } = getState().content;
      const params = composeQueryParams(payload, metadata);
      const response = await axios.get("/api/admin/contents", { params, paramsSerializer });
      return { contents: response.data };
    } catch (error) {
      console.log(error);
    }
  }
);

export const upsertContentAsync = createAsyncThunk(
  "content/upsert",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/api/content`, payload.content);
      return { content: response.data };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const readUserProgressAsync = createAsyncThunk(
  "content/readUserProgress",
  async (payload, { rejectWithValue }) => {
    try {
      const searchParams = new URLSearchParams(window.location.search);
      const authToken = searchParams.get("authToken") || searchParams.get("token");
      // the token will be ignored by the server if null and cookie will be used instead
      const response = await axios.get("/api/user/progress", { headers: { token: authToken } });
      return { userProgress: response.data };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteContentAsync = createAsyncThunk("/content/delete", async payload => {
  await axios.delete(`/api/content/${payload.content.id}`);
  return { id: payload.content.id };
});

export const getTagByPlatformAsync = createAsyncThunk("/tag/getTagByPlatform", async payload => {
  const response = await axios.get(`/api/tag/${payload.PlatformId}`);
  return { tags: response.data };
});

export const contentSlice = createSlice({
  name: "content",
  initialState: {
    loading: true,
    error: null,
    contents: [],
    collections: [],
    userProgress: [],
    progressLoaded: false,
    tags: [],
    metadata: initialDashboardPageMetadata
  },
  reducers: {
    clearError: (state, action) => {
      if (state.error) state.error[action.payload.field] = null;
    },
    clearErrors: state => {
      state.error = null;
    },
    updateUserProgress: (state, action) => {
      const { id, currentTime } = action.payload;
      const existingProgress = state.userProgress.find(p => p.ContentId === id);

      if (existingProgress) existingProgress.progress = currentTime;
      else state.userProgress.push({ ContentId: id, progress: currentTime });
    }
  },
  extraReducers: builder => {
    builder
      .addCase(readAllContentsForDashboardAsync.pending, state => {
        state.loading = true;
      })
      .addCase(readAllContentsForDashboardAsync.fulfilled, (state, action) => {
        state.loading = false;
        const { items, ...metadata } = action.payload.contents || {};
        state.contents = items || [];
        state.metadata = mergeDshbPageMetadataObjects(state.metadata, metadata);
      })
      .addCase(readAllContentsForDashboardAsync.rejected, state => {
        state.loading = false;
      })
      .addCase(upsertContentAsync.pending, state => {
        state.loading = true;
      })
      .addCase(upsertContentAsync.fulfilled, state => {
        state.loading = false;
      })
      .addCase(upsertContentAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error;
      })
      .addCase(readUserProgressAsync.pending, state => {
        state.loading = true;
      })
      .addCase(readUserProgressAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.progressLoaded = true;
        state.userProgress = action.payload.userProgress;
      })
      .addCase(readUserProgressAsync.rejected, state => {
        state.loading = false;
        state.progressLoaded = true;
      })
      .addCase(deleteContentAsync.pending, state => {
        state.loading = true;
      })
      .addCase(deleteContentAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.contents = state.contents.filter(content => content.id !== action.payload.id);
      })
      .addCase(deleteContentAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      })
      .addCase(getTagByPlatformAsync.pending, state => {
        state.loading = true;
      })
      .addCase(getTagByPlatformAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.tags = action.payload.tags;
      })
      .addCase(getTagByPlatformAsync.rejected, state => {
        state.loading = false;
        //state.error = action.error;
      });
  }
});
export const { clearError, clearErrors, updateUserProgress } = contentSlice.actions;
export default contentSlice.reducer;
