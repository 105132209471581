export const cdnUrl = process.env.REACT_APP_CDN_URL;
export const imgUrl = process.env.REACT_APP_IMG_URL;
export const NODE_NAME = process.env.REACT_APP_NODE_NAME;
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;

const urls = {
  cdnUrl,
  imgUrl
};

export default urls;
