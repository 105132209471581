import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useCallback } from "react";

export const readAllCoursesForDashboardAsync = createAsyncThunk(
  "course/readAllCoursesForDashboard",
  async ({ searchTerm, currentPage, showLoading }, { dispatch }) => {
    if (showLoading) dispatch(setLoading(true));
    try {
      const response = await axios.get("/api/admin/courses", {
        params: { searchTerm, page: currentPage }
      });
      return response.data;
    } catch (error) {
      throw error;
    } finally {
      if (showLoading) dispatch(setLoading(false));
    }
  }
);

const courseSlice = createSlice({
  name: "course",
  initialState: {
    courses: [],
    searchTerm: "",
    loading: false,
    error: null,
    initialized: false
  },
  reducers: {
    setSearchTerm: (state, action) => {
      state.searchTerm = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(readAllCoursesForDashboardAsync.fulfilled, (state, action) => {
        state.courses = action.payload;
        state.initialized = true;
      })
      .addCase(readAllCoursesForDashboardAsync.rejected, (state, action) => {
        state.error = action.error.message;
      });
  }
});

export const { setSearchTerm, setLoading } = courseSlice.actions;

export default courseSlice.reducer;

export const useCourses = () => {
  const dispatch = useDispatch();
  const { courses, searchTerm, loading, initialized } = useSelector(state => state.course);

  const fetchCourses = useCallback(
    async ({ searchTerm = "", showLoading = true }) => {
      await dispatch(readAllCoursesForDashboardAsync({ searchTerm, currentPage: 1, showLoading }));
    },
    [dispatch]
  );

  useEffect(() => {
    if (!initialized) fetchCourses({ searchTerm });
  }, [fetchCourses, searchTerm, initialized]);

  return { courses, searchTerm, loading, fetchCourses };
};
