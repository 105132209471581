import { Component } from "react";
import { useSelector } from "react-redux";
import { captureException } from "../utils/errorReporting";

// learn more about error boundaries here:
// https://reactjs.org/docs/error-boundaries.html
export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can log the error to an error reporting service
    const boundaryName = this.props.name || "untitled";
    console.error(`Crashed in ${boundaryName} error boundary.`, error);
    captureException(error, { boundaryName });
  }

  render() {
    if (this.state.hasError) {
      return this.props.renderComp ? this.props.renderComp() : <SomethingWentWrong />;
    }

    return this.props.children;
  }
}

const SomethingWentWrong = () => {
  const { platformData } = useSelector(state => state.frontend);
  const primaryColor = platformData?.primaryColor || "black";

  return (
    <div className="min-h-full w-full pt-16 pb-12 flex flex-col bg-white">
      <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
        <div className="py-16">
          <div className="text-center">
            <h1
              className="mt-2 text-4xl font-bold text-gray-900 tracking-tight sm:text-5xl sm:tracking-tight"
              style={{ color: `${primaryColor}` }}
            >
              Oops!
            </h1>
            <p className="mt-2 text-base text-gray-500">
              Something went wrong... try refreshing the page.
            </p>
          </div>
        </div>
      </main>
    </div>
  );
};
